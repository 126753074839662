<template>
    <el-dialog :visible.sync="dialogVisible" :fullscreen="true" :destroy-on-close="true" :lock-scroll="true"
               :before-close="cancel" @open="initSize">
        <div slot="title" class="titleContainer flex-row">
            <span class="text-18-black33-b">签名定位</span>
        </div>

        <div class="flex-row outContainer" ref="outContainer">
            <div class="flex1 flex-col back-color-grayE5"  style="border:1px solid #666666;">
                <div class="pdfPageHandler flex-row-center-jx" style="margin-left: 45%">
                    <i class="el-icon-d-arrow-left icon-30 btn-hover-el" @click="switchPage(false)"/>
                    <input :value="curPdfIndex" class="indexInput" type="number" @change="limitPage"/>
                    <span v-if="pdfPages.length!==1" class="pageFontSize">/{{pdfPages.length}} </span>
                    <i class="el-icon-d-arrow-right icon-30 btn-hover-el" @click="switchPage"></i>

                </div>
                <div class=" flex1 pdfBox" style="overflow: auto;padding: 16px;">
                    <div>
                        <div class="pdfPicContainer "
                             :style="{'height':dynamicHeight+'px','width':dynamicWidth+'px'}"
                             @dragover="$event.preventDefault()" @drop="onDragStop($event)">


                            <img v-if="curPdfPage" class="pdfPic" :src="curPdfPage" alt="" ref="bg-image"/>
                            <span v-else class="noPdfHint">暂无数据哦</span>
                            <div class="dragRect border-box" v-if="rect.pageCode === curPdfIndex" :class="'currently'+index" draggable="true" @dragstart="onDragStart(index,$event)"
                                 v-for="(rect, index) in locate" :key="index" :style="styleRect(rect)">
                                <div   v-if="rect.pageCode === curPdfIndex" :class="rect.type===1?'dragRectchirds':''"
                                     class="dragRectChrids  position-r match-parent flex-row-center" @click.stop.prevent>
                                    <div class="deleteIcon flex-row-center position-a" @click="deleteLocate(index)">x</div>
                                    <span v-if="rect.type === 1" class="text-14-black33">{{ rect.name }}</span>
                                    <div v-else-if="rect.type === 0" class="position-r flex-row-center match-parent">
                                        <img v-if="rect.orgType==='notaryAgency'" class="match-parent" src="../image/seal.png"
                                             alt=""/>
                                        <img v-if="rect.orgType==='financial'" class="match-parent" src="../image/Hseal.png"
                                             alt=""/>
                                        <span class="position-a signatureIndex">{{ rect.index }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

            <div class="flex-col signatureContainer padding-16">
                <span class="text-18-black33-b">签名/签章</span>
                <div class="signatureList flex-col margin-top-16">
                    <div class="flex-row flex-between margin-top-8 signatureLine">
                        <span class="text-14-black33 signatureItem flex-row-center">序号</span>
                        <span class="text-14-black33 signatureItem flex-row-center">人员/机构</span>
                        <span class="text-14-black33 signatureItem flex-row-center">状态</span>
                        <span class="text-14-black33 signatureItem flex-row-center">操作</span>
                    </div>

                    <div v-for="(sign,index) in signatureList" :key="index"
                         class="flex-row-center-jx flex-between margin-top-8 signatureLine">
                        <span class="text-14-gray66 signatureItem flex-row-center">{{ index + 1 }}</span>
                        <span class="text-14-gray66 signatureItem flex-row-center">{{ sign.name }}</span>
                        <span class="signatureStatus" :class="getSignatureClass(sign)">{{ getSignStatus(sign) }}</span>
                        <!--                        <span class="text-14-orangeFF signatureItem flex-row-center little-hand"-->
                        <!--                              @click="addNewSign(sign,index)">{{sign.needSet ? '+ 添加' : ''
                        }}</span>-->
                        <span class="text-14-orangeFF signatureItem flex-row-center little-hand"
                              @click="addNewSign(sign,index)">+添加</span>
                    </div>
                </div>
            </div>
        </div>

        <div slot="footer" class="dialog-footer">
            <el-button @click="cancel">取 消</el-button>
            <el-button type="warning" @click="saveLocation">确 定</el-button>
        </div>
    </el-dialog>

</template>

<script>
    import verify from "../tool/verify";

    export const PageSize = {
        width: 595,
        height: 842
    }
    export const ScreenSize = {
        width: 892,
        height: 1262
    }

    export default {
        name: 'pdfEditor',
        components: {},
        data() {
            return {
                dialogVisible: false,
                couldDrag: true,
                parentWidth: PageSize.width,
                parentHeight: PageSize.height,
                locate: [],// 所有签名定位信息
                locateCopy: [],
                signatureList: [], //右边列表数据
                pdfPages: [],
                curPdfIndex: 1,
                dynamicHeight: 100,
                dynamicWidth: 100,
                scale: 1,
                factor:0//移动系数
            }
        },
        props: {
            isVertical: {
                type: Boolean,
                default: () => {
                    return true;
                },
            },
        },
        computed: {
            curPdfPage() {
                if (verify.isEmptyArray(this.pdfPages)) {
                    // 如果没得pdf设置进来
                    return null
                }
                return this.pdfPages[this.curPdfIndex - 1];
            }
        },
        watch: {
            locate: {
                handler(newValue) {
                    console.log(`定位数据发生变化，新值=`, newValue);
                    let signatureCopy = JSON.parse(JSON.stringify(this.signatureList));
                    // 定位数据发生变化，去判定右侧有无指定
                    for (let i = 0; i < signatureCopy.length; i++) {
                        let curSignature = signatureCopy[i];
                        let signInLocation = this.locate.filter(item => {
                            return  item.type === 0 ? item.sealCode === curSignature.sealCode : item.litigantId === curSignature.litigantId
                        });
                        this.signatureList[i].haveSet = !verify.isEmptyArray(signInLocation);
                    }
                },
            },
        },
        mounted() {

        },
        methods: {
            initData(signList, pdfPages) {
                this.signatureList = signList;
                this.pdfPages = pdfPages;
            },
            initSize() {
            },
            restoreLocate(locateArray) {
                this.$nextTick(() => {
                    const value = window.devicePixelRatio;
                    let height = ScreenSize.height / value;
                    let width = ScreenSize.width / value;
                    console.log(`---[showEditor-height]----`, height);
                    this.dynamicHeight = height;
                    this.dynamicWidth = width;
                    this.scale = this.dynamicHeight / PageSize.height;
                    locateArray.forEach(itme => {
                        itme.width = Math.round(itme.width * this.scale);
                        itme.height = Math.round(itme.height * this.scale);
                        itme.lx = Math.round(itme.lx * this.scale);
                        itme.ly = Math.round(this.dynamicHeight - itme.ly * this.scale - itme.height);
                        // (this.dynamicHeight - val.ly - val.height) / scale

                    })
                    this.locate = locateArray;
                    this.locateCopy = JSON.parse(JSON.stringify(locateArray));
                });

            },
            getSignStatus(sign) {
                console.log(`---------------[sign]--------------`, sign);
                let {haveSet} = sign;
                return haveSet ? '已指定' : '未指定';
            },
            getSignatureClass(sign) {
                let {haveSet} = sign;
                return !haveSet ? 'haveNotSetSign' : 'haveSetSign';
            },
            switchPage(add = true) {

                if (this.curPdfIndex < 2 && !add) {
                    this.curPdfIndex=this.pdfPages.length
                    // this.$emit('onHint', {hint: '没有更多了哦！'});
                    return;
                }
                if (this.curPdfIndex >= this.pdfPages.length && add) {
                    this.curPdfIndex=1
                    // this.$emit('onHint', {hint: '没有更多了哦！'});
                    return;
                }

                if (add) {
                    this.curPdfIndex++;
                } else {
                    this.curPdfIndex--;
                }
            },
            limitPage(event) {
                let index = event.target.valueAsNumber;
                this.curPdfIndex = index;
                if (index < 2) {
                    this.curPdfIndex = 1;
                    return;
                }
                if (index >= this.pdfPages.length) {
                    this.curPdfIndex = this.pdfPages.length;
                }
            },
            onDragStart(index, event) {
                this.lastEvent = event
                event.dataTransfer.setData('number', index)
                console.log(`---------------[onDragStart-this.locate]--------------`, JSON.stringify(this.locate));
            },
            onDragStop(event) {
                const diffX = event.clientX - this.lastEvent.clientX
                const diffY = event.clientY - this.lastEvent.clientY
                const curSignIndex = event.dataTransfer.getData('number')
                if (curSignIndex !== -1) {
                    // 更新签字定位
                    const sign = this.locate[curSignIndex]
                    sign.ly += diffY;
                    sign.lx += diffX;
                    sign.pageCode = this.curPdfIndex;
                }
                console.log(`---------------[onDragStop-this.locate]--------------`, event);
            },
            addNewSign(sign, index) {
                this.factor++

                // if(this.factor>=5){
                //     this.factor=0
                //      this.$message({
                //         message: '请将签字或者签章,移动到合适位置',
                //         type: 'warning'
                //     });
                //     return
                // }
                let copy = JSON.parse(JSON.stringify(sign));
                console.log(copy, '==========>新增的时候数据')
                copy.width = Math.round(copy.width * this.scale);
                copy.height = Math.round(copy.height * this.scale);
                copy.ly =  (this.locate===0?this.factor: this.factor*5)+(this.dynamicHeight - copy.height) / 2;
                copy.lx = (this.locate===0?this.factor: this.factor*5)+(this.dynamicWidth - copy.width) / 2;
                copy.pageCode = this.curPdfIndex;
                copy.index = index + 1;
                copy.addTime = new Date().getTime();
                this.locate.push(copy);
            },
            deleteLocate(index) {
                console.log(`---------------[删之前-this.locate]--------------`, JSON.stringify(this.locate));
                if( this.factor!=0){
                    this.factor--
                }

                this.locate.splice(index, 1);
                if(this.locate.length===0){
                    this.factor=0
                }
                console.log(`---------------[删之后-this.locate]--------------`, JSON.stringify(this.locate));
            },
            saveLocation() {
                console.log(`---------------[locate]--------------`, this.locate);
                // 先进行点位确认
                for (const signature of this.signatureList) {
                    // 去定位列表里面找，哪一个人没有定位，然后提醒他
                    let haveLocate = this.locate.some(v => {
                        return v.name === signature.name;

                    });
                    if (!haveLocate) {
                        let oneCopy = JSON.parse(JSON.stringify(this.locate));
                        oneCopy.forEach(val => {
                            const scale = this.scale;
                            val.lx = Math.round(val.lx / scale);
                            val.ly = Math.round((this.dynamicHeight - val.ly - val.height) / scale);
                            val.width = Math.round(val.width / scale);
                            val.height = Math.round(val.height / scale);
                        })

                        this.$emit('onHint', {
                            hint: `${signature.name}的${signature.type === 1 ? "签字" : '签章'}未指定,是否保存？`,
                            data: oneCopy
                        });
                        return;
                    }
                }
                let copy = JSON.parse(JSON.stringify(this.locate));
                let finalLocate = copy.map(info => {
                    let {width, height, ly, lx} = info;
                    const scale = this.scale;
                    info.lx = Math.round(lx / scale);
                    info.ly = Math.round((this.dynamicHeight - ly - height) / scale);
                    info.width = Math.round(width / scale);
                    info.height = Math.round(height / scale);
                    return info;
                });

                this.$emit('onComplete', {
                    orientation: this.isVertical ? 'VERTICAL' : 'HORIZONTAL',
                    points: finalLocate
                });
                console.log(`---------------[转换后的locateInfo]--------------`, finalLocate);
                this.hideEditor();
            },
            showEditor(signList, pdfPages) {
                this.dialogVisible = true;
                this.$nextTick(() => {
                    const value = window.devicePixelRatio;
                    let height = ScreenSize.height / value;
                    let width = ScreenSize.width / value;
                    console.log(`---[showEditor-height]----`, height);
                    this.dynamicHeight = height;
                    this.dynamicWidth = width;
                    this.scale = this.dynamicHeight / PageSize.height;
                });
                this.initData(signList, pdfPages);
            },
            cancel() {
                console.log(`---------------[this.haveLocateChange(this.locate, this.locateCopy)]--------------`, this.haveLocateChange(this.locate, this.locateCopy));
                if (this.haveLocateChange(this.locate, this.locateCopy)) {
                    this.$emit('cancelChange');
                    return;
                }
                this.hideEditor();
            },
            hideEditor() {
                this.dialogVisible = false;
                this.factor=0
            },
            styleRect(rect) {
                return {
                    width: rect.width + 'px',
                    height: rect.height + 'px',
                    top: rect.ly + 'px',
                    left: rect.lx + 'px',
                }
            },
            haveLocateChange(locate, oldLocate) {
                if (locate.length !== oldLocate.length) return true;
                for (const signature of locate) {
                    let {name, lx, ly} = signature;
                    console.log(`---------------[signature]--------------`, signature);
                    let oldSignature = oldLocate.find(oldValue => oldValue.name === name);
                    console.log(`---------------[oldSignature]--------------`, oldSignature);
                    // 如果找不到这一条，那么就是有变更
                    if (verify.isEmpty(oldSignature)) return true;
                    if (oldSignature.lx !== lx || oldSignature.ly !== ly) return true;
                }
                return false;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .pdfPicContainer {
        width: 630px;
        height: 879px;
        margin: 0 auto;
        position: relative;
        background-color: #EBEBEB;

        .pdfPic {
            height: 100%;
            z-index: 1;
            box-shadow: 1px 1px 1px 1px #dad6d6;
        }

        .noPdfHint {
            align-self: center;
            top: 46%;
            position: absolute;
            font-size: 32px;
            font-weight: bold;
            color: #333333;
        }

        .dragRect {
            position: absolute;
            z-index: 999;
            /*background-color: #999999;*/
            //border-radius: 2px;
            display: inline-block;

        }
        .dragRectChrids{
            border: 1px dotted #666666;
        }

        .dragRectchirds {
            background-color: #99999999;
        }

        .pdfPageHandler {
            position: absolute;
            left: calc(50% - 48px);
            z-index: 100;
            align-self: center;
        }


    }

    .parties {
        margin-top: 1%;
        width: 40%;
        height: 60vh;
        border: 1px solid #666666;
    }

    .signatureStyle {
        border-radius: 8px;
        border: 1px solid #EBEBEB;
        padding: 0 4px;

        &:active {
            background-color: greenyellow;
        }
    }

    .signatureContainer {
        width: 20%;
        padding: 0 16px;
        margin-left: 8px;
    }

    .signName {
        width: 100%;
        height: 100%;
        text-align: center;
        line-height: 100%;
    }

    .outContainer {
        height: 86vh;
    }

    ::v-deep .el-dialog {
        margin: 0 auto 0;

        .el-dialog__body {
            padding: 0 20px;
        }

        .el-dialog__footer {
            padding: 8px 20px 10px;
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    .deleteIcon {
        border-radius: 50%;
        width: 20px;
        height: 20px;
        min-height: 20px;
        min-width: 20px;
        line-height: 20px;
        text-align: center;
        padding: 2px;
        color: #333333;
        background-color: #EBEBEB;
        font-size: 12px;
        top: -12px;
        right: -12px;
        z-index: 2;
    }

    .deleteIcon:active {
        /*background-color: #999999;*/
    }

    .signatureList {
        border-top: 1px solid #E0E0E0;
        padding: 0 8px;
    }

    .signatureLine {
        /*height: 40px;*/
        min-height: 40px;
        border-bottom: 1px solid #E0E0E0;
    }

    .signatureItem {
        min-width: 60px;
        width: 70px;
    }

    .tipLayout {
        width: 48px;
        height: 20px;
        border-radius: 2px;
    }

    .titleContainer {
        height: 40px;
        border-bottom: 1px solid #EBEBEB;
    }

    .signatureStatus {
        width: 48px;
        height: 20px;
        border-radius: 2px;
        font-size: 8px;
        line-height: 20px;
        text-align: center;
    }

    .haveSetSign {
        color: #32D26B;
        font-size: 12px;
        background-color: rgba(50, 210, 107, 0.2)
    }

    .haveNotSetSign {
        font-size: 12px;
        color: #E31E26;
        background-color: rgba(227, 30, 38, 0.15);
    }

    .signatureIndex {
        font-size: 30px;
        font-weight: bold;
    }
    .pageFontSize{
        font-size: 18px;
        /*font-weight: bold;*/
        /*position: absolute;*/
        /*right: 0;*/
    }
    .indexInput {
        margin: 0 4px;
        width: 50px;
        height: 20px;
        text-align: center;

    }


    .pdfBox::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    .pdfBox::-webkit-scrollbar-track {
        background: rgb(239, 239, 239);
        border-radius: 2px;
    }

    .pdfBox::-webkit-scrollbar-thumb {
        background: #bfbfbf;
        border-radius: 10px;
    }

    .pdfBox::-webkit-scrollbar-thumb:hover {
        background: #333;
    }

    .pdfBox::-webkit-scrollbar-corner {
        background: #179a16;
    }
    //@formatter:on
</style>
