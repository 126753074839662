var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        fullscreen: true,
        "destroy-on-close": true,
        "lock-scroll": true,
        "before-close": _vm.cancel,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        open: _vm.initSize,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "titleContainer flex-row",
          attrs: { slot: "title" },
          slot: "title",
        },
        [_c("span", { staticClass: "text-18-black33-b" }, [_vm._v("签名定位")])]
      ),
      _vm._v(" "),
      _c("div", { ref: "outContainer", staticClass: "flex-row outContainer" }, [
        _c(
          "div",
          {
            staticClass: "flex1 flex-col back-color-grayE5",
            staticStyle: { border: "1px solid #666666" },
          },
          [
            _c(
              "div",
              {
                staticClass: "pdfPageHandler flex-row-center-jx",
                staticStyle: { "margin-left": "45%" },
              },
              [
                _c("i", {
                  staticClass: "el-icon-d-arrow-left icon-30 btn-hover-el",
                  on: {
                    click: function ($event) {
                      return _vm.switchPage(false)
                    },
                  },
                }),
                _vm._v(" "),
                _c("input", {
                  staticClass: "indexInput",
                  attrs: { type: "number" },
                  domProps: { value: _vm.curPdfIndex },
                  on: { change: _vm.limitPage },
                }),
                _vm._v(" "),
                _vm.pdfPages.length !== 1
                  ? _c("span", { staticClass: "pageFontSize" }, [
                      _vm._v("/" + _vm._s(_vm.pdfPages.length) + " "),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("i", {
                  staticClass: "el-icon-d-arrow-right icon-30 btn-hover-el",
                  on: { click: _vm.switchPage },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: " flex1 pdfBox",
                staticStyle: { overflow: "auto", padding: "16px" },
              },
              [
                _c("div", [
                  _c(
                    "div",
                    {
                      staticClass: "pdfPicContainer ",
                      style: {
                        height: _vm.dynamicHeight + "px",
                        width: _vm.dynamicWidth + "px",
                      },
                      on: {
                        dragover: function ($event) {
                          return $event.preventDefault()
                        },
                        drop: function ($event) {
                          return _vm.onDragStop($event)
                        },
                      },
                    },
                    [
                      _vm.curPdfPage
                        ? _c("img", {
                            ref: "bg-image",
                            staticClass: "pdfPic",
                            attrs: { src: _vm.curPdfPage, alt: "" },
                          })
                        : _c("span", { staticClass: "noPdfHint" }, [
                            _vm._v("暂无数据哦"),
                          ]),
                      _vm._v(" "),
                      _vm._l(_vm.locate, function (rect, index) {
                        return rect.pageCode === _vm.curPdfIndex
                          ? _c(
                              "div",
                              {
                                key: index,
                                staticClass: "dragRect border-box",
                                class: "currently" + index,
                                style: _vm.styleRect(rect),
                                attrs: { draggable: "true" },
                                on: {
                                  dragstart: function ($event) {
                                    return _vm.onDragStart(index, $event)
                                  },
                                },
                              },
                              [
                                rect.pageCode === _vm.curPdfIndex
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "dragRectChrids  position-r match-parent flex-row-center",
                                        class:
                                          rect.type === 1
                                            ? "dragRectchirds"
                                            : "",
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            $event.preventDefault()
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "deleteIcon flex-row-center position-a",
                                            on: {
                                              click: function ($event) {
                                                return _vm.deleteLocate(index)
                                              },
                                            },
                                          },
                                          [_vm._v("x")]
                                        ),
                                        _vm._v(" "),
                                        rect.type === 1
                                          ? _c(
                                              "span",
                                              {
                                                staticClass: "text-14-black33",
                                              },
                                              [_vm._v(_vm._s(rect.name))]
                                            )
                                          : rect.type === 0
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "position-r flex-row-center match-parent",
                                              },
                                              [
                                                rect.orgType === "notaryAgency"
                                                  ? _c("img", {
                                                      staticClass:
                                                        "match-parent",
                                                      attrs: {
                                                        src: require("../image/seal.png"),
                                                        alt: "",
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                rect.orgType === "financial"
                                                  ? _c("img", {
                                                      staticClass:
                                                        "match-parent",
                                                      attrs: {
                                                        src: require("../image/Hseal.png"),
                                                        alt: "",
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "position-a signatureIndex",
                                                  },
                                                  [_vm._v(_vm._s(rect.index))]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            )
                          : _vm._e()
                      }),
                    ],
                    2
                  ),
                ]),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "flex-col signatureContainer padding-16" }, [
          _c("span", { staticClass: "text-18-black33-b" }, [
            _vm._v("签名/签章"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "signatureList flex-col margin-top-16" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "flex-row flex-between margin-top-8 signatureLine",
                },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "text-14-black33 signatureItem flex-row-center",
                    },
                    [_vm._v("序号")]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass:
                        "text-14-black33 signatureItem flex-row-center",
                    },
                    [_vm._v("人员/机构")]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass:
                        "text-14-black33 signatureItem flex-row-center",
                    },
                    [_vm._v("状态")]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass:
                        "text-14-black33 signatureItem flex-row-center",
                    },
                    [_vm._v("操作")]
                  ),
                ]
              ),
              _vm._v(" "),
              _vm._l(_vm.signatureList, function (sign, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass:
                      "flex-row-center-jx flex-between margin-top-8 signatureLine",
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass:
                          "text-14-gray66 signatureItem flex-row-center",
                      },
                      [_vm._v(_vm._s(index + 1))]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass:
                          "text-14-gray66 signatureItem flex-row-center",
                      },
                      [_vm._v(_vm._s(sign.name))]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "signatureStatus",
                        class: _vm.getSignatureClass(sign),
                      },
                      [_vm._v(_vm._s(_vm.getSignStatus(sign)))]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass:
                          "text-14-orangeFF signatureItem flex-row-center little-hand",
                        on: {
                          click: function ($event) {
                            return _vm.addNewSign(sign, index)
                          },
                        },
                      },
                      [_vm._v("+添加")]
                    ),
                  ]
                )
              }),
            ],
            2
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取 消")]),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "warning" }, on: { click: _vm.saveLocation } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }